import React, { createContext, useEffect } from 'react'
import { Trans, useTranslation } from 'gatsby-plugin-react-i18next'
import { graphql } from 'gatsby'
import GlobalStyle from '../GlobalStyles'
import logo from '../assets/images/logo-branco.png'
import styled from 'styled-components'
import {
    notfondContext,
    frame
} from '../images/redirectLab'

import BgFundo  from '../assets/images/redirectLab/bgAzul.png'


export const NotfoundLab = styled.div`
    font-family: 'ASICSFont3.0-Regular', sans-serif;
    background-color: #001E62;
    background-image: url(${BgFundo});
    background-repeat: no-repeat;
    background-position: center top;
    background-size: cover;
    min-height: 100vh;
    width: 100%;
    margin-top: -70px;
    padding-right: 16px;
    background-position: center;
    background-size: cover;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 40px;
`

export const BoxConteudo = styled.div`
    display: flex;
    justify-content: space-around;
    align-items: center;
    border-radius: 25px;
    background: linear-gradient(180deg, #F7F1FF 0%, #D2C9FF 100%);
    width: 75vw;
    max-width: 950px;
    @media (max-width: 945px){
      padding: 20px 0;
      width: 80vw;
    }
`

export const BoxTexts = styled.div`
    padding: 0px 15px 0px 30px;
    width: 100%;
    div{
      display: flex;
      align-items: baseline;
      gap: 30px;
      img{
        width: 22%;
      }
      @media (max-width: 945px){
        img{
          width: 24%;
        }
      }
    }
    h1{
      color: #001E62;
      font-size: 47.18px;
      font-style: italic;
      font-weight: 700;
      line-height: 40.44px;
    }
    p{
      color: #001E62;
      font-size: 25px;
      font-style: normal;
      font-weight: 400;
      line-height: 35px;
      padding-top: 15px;
      padding-bottom: 30px;
    }
    a{
      border-radius: 175.355px;
      background: #001E62;
      color: #FFF;
      text-align: center;
      font-size: 20px;
      font-style: normal;
      font-weight: 700;
      line-height: 22px; /* 110% */
      text-transform: uppercase;
      text-decoration: none;
      padding: 12px 35px;
    }
    @media (max-width: 945px){
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      padding: 10px 30px;

      h1{
        font-size: 28px;
      }
      p{
        font-size: 24px;
        line-height: 26px;
      }
    }
`
export const BoxImgContext = styled.div`
    width: 100%;
    display: flex;
    justify-content: flex-end;
    @media (max-width: 945px){
        display: none;
    }
`


const NotFoundPage = () => {
  return (
      <NotfoundLab>
        <GlobalStyle />
        <BoxConteudo>
          <BoxTexts>
            <div>
              <h1><Trans>Não Encontrado</Trans></h1>
              <img src={frame} alt="Asics"/>
            </div>
            <p><Trans>Desculpe, a página que você está procurando não existe.</Trans></p>
            <a href="https://www.asics.com.br/"><Trans>Ir para o Site Oficial da ASICS</Trans></a>
          </BoxTexts>
          <BoxImgContext>
            <img src={notfondContext} alt="" />
          </BoxImgContext>
        </BoxConteudo>
        <div>
          <img src={logo} alt="Asics" width="142" height="48" />
        </div>
      </NotfoundLab>
    
  )
}

export default NotFoundPage

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`
